import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Realisations from './components/Realisations/Realisations';
import ContactFooter from './components/ContactFooter/ContactFooter';
import Cookie from './components/Cookie/Cookie';
import Youtube from './components/Youtube/Youtube'
import Mentions from './components/Mentions/Mentions';
import Confidentialite from './components/Confidentialite/Confidentialite';
import CookieConsent from 'react-cookie-consent';

function App() {
  return (
    <div className='lineaire-simple'>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/Realisations" element={<Realisations />} />
          <Route path="/Mentions" element={<Mentions />} />
          <Route path="/Confidentialite" element={<Confidentialite />} />
        </Routes>
      </BrowserRouter>
      <ContactFooter />
      <Footer />
      {/*
      <CookieConsent
        debug={true}
        style={{ background: "#1E90FF", color:"#000000", opacity:1 }}
        buttonStyle={{ color: "#000000", background: "#9E9E9E",borderRadius:5 }}
        buttonText="Je comprends"
        expires={365}>
        Ce site utilise des cookies. Veuillez lire notre <a href="/Confidentialite" style={{ textDecoration: "underline" }}>politique de confidentialité </a>.</CookieConsent>
        */}
    </div>


  );
}

export default App;
