import React from 'react';
import Video from 'react-youtube';
import './Youtube.css';

class YouTube extends React.Component {
  render() {
    // Remplacez 'VIDEO_ID' par l'ID de la vidéo YouTube que vous souhaitez afficher
    const videoId = 'https://youtu.be/oIGciUzcQWs';

    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 1, // Mettez à 1 pour démarrer la lecture automatiquement
      },
    };

    return (
      <Video
        videoId={videoId}
        opts={opts}
        onReady={this._onReady}
        // Ajoutez d'autres événements et propriétés nécessaires selon vos besoins
      />
    );
  }

  _onReady(event) {
    // Accédez à l'objet player via l'événement
    event.target.pauseVideo(); // Par exemple, pour mettre en pause la vidéo dès qu'elle est prête
  }
}

export default YouTube;
