import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Mentions = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col xs={10}>
                        <br />
                        <h1>Mentions Légales</h1>
                        <br />
                        <p>Le site internet novablex.be est édité par Nova Blex, une société SRL, immatriculée au registre du commerce et des sociétés de Grimbergen sous le numéro BE0800.277.318 et dont le siège social est situé à Leopoldstraat 4, 1980 Zemst, Belgique <br/><br/>
                            Directeur de la publication : Bledar Dauti <br/>
                            Numéro de téléphone : 32 485 71 55 54 <br/>
                            Adresse e-mail : info@novablex.be <br/>
                            Webmaster : Blerim Mata <br/><br/>
                            Le site internet novablex.be est hébergé par OVH,  situé rue Kellermann 2 59100 Roubaix, téléphone : 1007, support@ovh.net.<br/>
                            Le site internet novablex.be est soumis à la législation belge en vigueur. Toute utilisation du site internet novablex.be est régie par ces mentions légales ainsi que par les conditions d'utilisation et la politique de confidentialité accessibles sur le site.<br/>
                            Toute reproduction, représentation, modification, publication, transmission, dénaturation, totale ou partielle du site internet novablex.be ou de son contenu, par quelque procédé que ce soit, et sur quelque support que ce soit, est interdite, sauf autorisation préalable et écrite de Nova Blex SRL.<br/>
                            Toute utilisation non autorisée du site internet novablex.be ou de son contenu engage la responsabilité de l'utilisateur et constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.<br/>
                            Les marques et logos figurant sur le site internet novablex.be sont des marques déposées de Nova Blex SRL ou de tiers. Toute reproduction, imitation ou usage, total ou partiel, de ces signes distinctifs sans l'autorisation expresse et préalable de leur titulaire est prohibé, au sens de l'article L.713-2 du Code de la propriété intellectuelle.<br/>
                            Les informations contenues sur le site internet novablex.be sont fournies à titre indicatif et ne sauraient engager la responsabilité de Nova Blex SRL. Nova Blex SRL se réserve le droit de modifier, à tout moment et sans préavis, le contenu du site internet novablex.be.<br/>
                            Le site internet novablex.be peut contenir des liens hypertextes vers des sites internet de tiers, Nova Blex SRL ne peut être tenu responsable du contenu de ces sites et ne contrôle pas leur politique de confidentialité ou leurs conditions d'utilisation.<br/>
                            Tout litige découlant de l'utilisation du site internet novablex.be sera soumis à la compétence exclusive des tribunaux belges. <br/><br/>
                            Dernière mise à jour : 9/05/2023 <br/>
                        </p>
                    </Col>
                    <br />
                    <br />
                    <br />
                </Row>
            </Container>
        </div>
    );
};

export default Mentions;