import logo from './logo.svg';
import './NavBar.css';
import React, { useState, useEffect } from 'react';
import { Navbar, Container} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";

function NavBar() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div className="App">
      <header>
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                height="60"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </Navbar.Brand>
            
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse className="justify-content-center">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="/">Accueil</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/About">À propos</a>
                </li>
                {/* 
                <li class="nav-item">
                  <a class="nav-link" href="/Contact">Contact</a>
                </li>*/}
                <li class="nav-item">
                  <a class="nav-link" href="/Realisations">Réalisations</a>
                </li>
              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>

  );
}



export default NavBar;