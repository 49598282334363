import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './text.css';

const TEXTS = [' CRÉONS', ' RÉPARONS', ' SATISFAISONS'];
const TRANSITION_DURATION = 1000; // Durée de la transition en millisecondes
const INTERVAL = 2000; // Intervalle entre chaque changement de texte en millisecondes

function Carrousel() {

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentTextIndex((currentTextIndex + 1) % TEXTS.length);
      }, TRANSITION_DURATION);
    }, INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentTextIndex]);

  return (
    <Container className="vh-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col className={`text-center ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
          <h1>Nous{TEXTS[currentTextIndex]}</h1>
          <h2 className="text-secondary text-center fade-inn">
          Construisons votre avenir ensemble. Des projets solides, des réalisations durables.
          </h2>
        </Col>
        <div className="row">
        <div className="col-12 text-center mt-5">
        {/*<Button className='custom' variant="outline-dark" href="/Contact">Contactez-nous</Button>*/}
        </div>
      </div>
      </Row>
      
    </Container>

  );
}

export default Carrousel;
