import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from './logo.svg';
import './ContactFooter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

function ContactFooter() {
    return (
        <footer className="bg-dark border-top border-dark">
            <Container>
                <Row className=" justify-content-center align-items-center text-center">
                    <Col md={3}>
                        <img
                            src={logo}
                            height="100"
                            className="d-inline-block align-top"
                            alt="Logo"
                        />
                    </Col>

                    <Col md={3}>
                        <br />

                        <p className="text-white "><a href="/">Accueil</a></p>
                        <p className="text-white "><a href="/About">À propos</a></p>
                     {/*   <p className="text-white "><a href="/Contact">Contact</a></p>*/}
                        <p className="text-white "><a href="/Realisations">Réalisations</a></p>
                        <p className="text-white "><a href="/Mentions">Mentions légales</a></p>
                        <p className="text-white "><a href="/Confidentialite">Politique de confidentialité</a></p>
                        <br />
                    </Col>

                    <Col md={3}>
                        <p className="text-white ">Leopoldstraat 4<br />1980 Zemst, Belgique<br /></p>
                    </Col>
                    <Col md={3}>
                        <p className="text-white">Téléphone : +32 485 71 55 54<br />Email : info@novablex.be</p><br />
                        <div className="mb-2 d-flex justify-content-evenly">
                            <a href="https://www.facebook.com/people/Nova-Blex/100092209881012/"><FontAwesomeIcon icon={faFacebook} size="2xl" /></a>
                            <a href="https://www.instagram.com/novablex/"><FontAwesomeIcon icon={faInstagram} size="2xl" /></a>
                        </div>
                        <br/><br/>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default ContactFooter;
