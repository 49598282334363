import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="bg-dark mt-10 border-top border-grey">
      <Container>
        <Row>
          <Col lg="12" className="text-center">
            <p className="text-white">Tous droits réservés © 2023, Nova Blex </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
