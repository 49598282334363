import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Confidentialite = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col xs={10}>
                        <br />
                        <h1>Politique de confidentialité</h1>
                        <br />
                        <p>La présente politique de confidentialité régit la manière dont novablex.be collecte, utilise, conserve et divulgue les informations recueillies auprès des utilisateurs de novablex.be. Cette politique de confidentialité s'applique au Site web et à tous les produits et services offerts par www.novablex.be.<br /><br />
                            <ol>
                                <li>Collecte d'informations personnelles</li><br />
                                Nous pouvons collecter des informations personnelles auprès des Utilisateurs de différentes manières, notamment lorsque les Utilisateurs visitent notre Site web, s'inscrivent sur le Site web, remplissent un formulaire ou en relation avec d'autres activités, services, fonctionnalités ou ressources que nous mettons à disposition sur notre Site web. Les informations personnelles que nous demandons aux Utilisateurs peuvent inclure, sans s'y limiter, leur nom, leur adresse e-mail, leur adresse postale et leur numéro de téléphone.<br /><br />
                                <li>Utilisation des informations collectées</li><br />
                                Nous pouvons collecter et utiliser les informations personnelles des Utilisateurs pour les raisons suivantes :<br /><br />
                                <ul>
                                    <li>	Améliorer le service client : Les informations que vous nous fournissez nous aident à répondre de manière plus efficace à vos demandes de service client et à vos besoins de support.</li>
                                    <li>	Personnaliser l'expérience utilisateur : Nous pouvons utiliser les informations dans leur ensemble pour comprendre comment nos Utilisateurs en tant que groupe utilisent les services et les ressources fournis sur notre Site web.</li>
                                    <li>	Améliorer notre Site web : Nous nous efforçons continuellement d'améliorer nos offres de produits et de services en fonction des informations et des commentaires que nous recevons de la part des Utilisateurs.</li>
                                    <li>	Envoyer des e-mails périodiques : Nous pouvons utiliser l'adresse e-mail fournie par l'Utilisateur pour leur envoyer des informations et mises à jour concernant leur commande. Elle peut également être utilisée pour répondre à leurs questions, demandes ou autres demandes. Si l'Utilisateur décide de s'abonner à notre liste de diffusion, il recevra des e-mails pouvant inclure des nouvelles de l'entreprise, des mises à jour, des informations sur des produits ou services connexes, etc. À tout moment, l'Utilisateur peut se désabonner de la réception de futurs e-mails en suivant les instructions de désabonnement fournies dans chaque e-mail.</li><br /><br />
                                </ul>
                                <li>Protection des informations</li><br />
                                Nous adoptons des pratiques appropriées de collecte, de stockage et de traitement des données, ainsi que des mesures de sécurité pour protéger contre tout accès non autorisé, toute altération, toute divulgation ou toute destruction des informations personnelles, noms d'utilisateur, mots de passe, informations de transaction et données stockées sur notre Site web.<br /><br />
                                <li>Partage de vos informations personnelles</li><br />
                                Nous ne vendons, n'échangeons et ne louons pas les informations personnelles des Utilisateurs à des tiers. Nous pouvons partager les informations personnelles des Utilisateurs uniquement dans les circonstances suivantes :<br /><br />
                                <ul>
                                    <li>	Fournisseurs de services tiers : Nous pouvons faire appel à des tiers de confiance pour nous aider à exploiter notre Site web et à mener nos activités, tels que l'hébergement du site, le traitement des paiements, l'analyse des données et l'envoi d'e-mails. Ces tiers auront accès aux informations personnelles des Utilisateurs uniquement dans la mesure nécessaire pour remplir leurs fonctions et ils sont tenus de respecter la confidentialité de ces informations.</li>
                                    <li>	Conformité à la loi : Nous pouvons divulguer des informations personnelles si nous y sommes légalement tenus ou si nous pensons de bonne foi que cette divulgation est nécessaire pour se conformer à une obligation légale, protéger nos droits, prévenir la fraude ou assurer la sécurité de nos Utilisateurs.</li>
                                    <li>	Consentement de l'Utilisateur : Nous pouvons partager les informations personnelles des Utilisateurs avec leur consentement exprès préalable.</li><br /><br />
                                </ul>
                                <li>Conservation des informations</li><br />
                                Nous conserverons les informations personnelles des Utilisateurs aussi longtemps que nécessaire pour atteindre les finalités mentionnées dans cette politique de confidentialité, à moins qu'une période de conservation plus longue ne soit requise ou autorisée par la loi.<br /><br />
                                <li>Protection de la vie privée des enfants</li><br />
                                Notre Site web ne s'adresse pas aux personnes de moins de 18 ans. Nous ne collectons pas sciemment d'informations personnelles auprès d'enfants de moins de 18 ans. Si vous êtes un parent ou un tuteur et que vous avez connaissance que votre enfant nous a fourni des informations personnelles, veuillez nous contacter afin que nous puissions supprimer ces informations de nos systèmes.<br /><br />
                                <li>Modifications de cette politique de confidentialité</li><br />
                                Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. Lorsque nous le ferons, nous réviserons la date de mise à jour au bas de cette page. Nous encourageons les Utilisateurs à consulter fréquemment cette page pour rester informés des modifications apportées à la manière dont nous protégeons les informations personnelles que nous collectons. Vous reconnaissez et acceptez qu'il est de votre responsabilité de revoir périodiquement cette politique de confidentialité et de vous familiariser avec les modifications.<br /><br />
                                <li>Acceptation de ces conditions</li><br />
                                En utilisant ce Site web, vous signifiez votre acceptation de cette politique. Si vous n'acceptez pas cette politique, veuillez ne pas utiliser notre Site web. Votre utilisation continue du Site web après l'affichage de modifications de cette politique sera considérée comme votre acceptation de ces modifications.
                                Si vous avez des questions concernant cette politique de confidentialité ou nos pratiques en matière de collecte et de traitement des informations personnelles, veuillez nous contacter aux coordonnées suivantes :
                                <br /><br />
                                info@novablex.be
                                <br />
                            </ol>
                            Date de dernière mise à jour : 19/05/2023

                        </p>
                    </Col>
                    <br />
                    <br />
                    <br />
                </Row>
            </Container>
        </div>
    );
};

export default Confidentialite;