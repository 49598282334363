import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const About = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col xs={10}>
            <br/>
            <h1>À propos</h1>
            <br/>
            <p>La société de rénovation Nova Blex est une entreprise spécialisée dans la rénovation intérieure et extérieure des bâtiments résidentiels et commerciaux. Avec plus de 10 ans d'expérience dans le domaine de la construction, l'équipe de professionnels de Nova Blex s'engage à fournir des services de haute qualité à ses clients.<br /><br />

              L'un des arguments clés de la société de rénovation Nova Blex est son approche personnalisée pour chaque projet. Chaque client est traité de manière individuelle, avec une attention particulière accordée à ses besoins et ses préférences. L'équipe de Nova Blex travaille en étroite collaboration avec les clients pour comprendre leurs objectifs et créer des solutions de rénovation sur mesure qui répondent à leurs attentes.<br /><br />

              La société de rénovation Nova Blex utilise les dernières techniques et technologies pour garantir un résultat final de haute qualité. Les matériaux utilisés sont soigneusement sélectionnés pour leur durabilité et leur esthétique, afin de garantir une finition impeccable. Les travaux sont effectués avec un grand souci du détail et dans le respect des délais impartis, afin de minimiser les perturbations pour les clients.<br /><br />

              En plus de son expertise technique, la société de rénovation Nova Blex est également connue pour son excellent service client. L'équipe est disponible pour répondre aux questions et fournir des conseils tout au long du processus de rénovation, offrant ainsi une expérience client exceptionnelle.<br /><br />

              En somme, la société de rénovation Nova Blex est un choix idéal pour tous ceux qui cherchent des services de rénovation de haute qualité, personnalisés et professionnels, avec un engagement fort envers la satisfaction du client.</p>
              </Col>
              <br/>
              <br/>
              <br/>
        </Row>
      </Container>
    </div>
  );
};

export default About;
