import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import './Contact.css'
import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {

  const form = useRef();
  var text;
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_onl7d9n', 'template_c4dckmr', form.current, 'WTGC01HeZz3Z8mEuv')
      .then((result) => {
          console.log(result.text);
          text = " message envoyé. ";
          console.log(text);
      }, (error) => {
          console.log(error.text);
          text = " Erreur ! veuillez réessayez.";
          console.log(text);
          
      });
      handleSubmit(e);
  };

  const [afficherAlert, setAfficherAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [variantAlert, setVariantAlert] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    // Code pour soumettre le formulaire

    // Si le formulaire est soumis avec succès
    setAfficherAlert(true);
    setMessageAlert("Le formulaire a été soumis avec succès !");
    setVariantAlert("success");
  }


  return (
    <div >
      <Container>
        <br/>
        <Row className="justify-content-md-center">
          <Col xs={12} md={8} lg={6}>
            <h1 className='text-center'>Formulaire de contact</h1>
            <Form ref={form} onSubmit={sendEmail}>
              <Form.Group controlId="formName">
                <Form.Label>Nom</Form.Label>
                <Form.Control type="text" name="user_name" placeholder="Entrez votre nom" required />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Adresse e-mail</Form.Label>
                <Form.Control type="email" name="user_mail" placeholder="Entrez votre adresse e-mail" required />
              </Form.Group>
              <Form.Group controlId="formSubject">
                <Form.Label>Numéro de téléphone</Form.Label>
                <Form.Control type="number" name="user_phone" placeholder="Entrez le numéro de téléphone" required />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" name="message" rows={5} placeholder="Entrez votre message" required />
              </Form.Group>
              <br/>
              <Button type='submit' className='custom' variant="outline-dark">Envoyez</Button>
            </Form>
            <br/>
            {afficherAlert &&
        <Alert variant={variantAlert}>
          {messageAlert}
        </Alert>
      }
            <br/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
