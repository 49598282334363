import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import './Realisations.css'
import Fade from 'react-reveal/Fade';
import carr1 from './image-nov/carrelage/carr1.jpg';
import carr2 from './image-nov/carrelage/carr2.jpg';
import carr3 from './image-nov/carrelage/carr3.jpg';

import pav1 from './image-nov/pavé/pavé1.jpg';
import pav2 from './image-nov/pavé/pavé2.jpg';
import pav3 from './image-nov/pavé/pavé3.jpg';
import pav4 from './image-nov/pavé/pavé4.jpg';

import fini1 from './image-nov/rdc/fini1.jpg';
import fini2 from './image-nov/rdc/fini2.jpg';
import salon1 from './image-nov/rdc/salon1.jpg';
import salon2 from './image-nov/rdc/salon2.jpg';

import sdb1 from './image-nov/salle de bain/sdb1.JPG'
import sdb2 from './image-nov/salle de bain/sdb2.JPG'

import toit1 from './image-nov/toiture/toit1.JPG'
import toit2 from './image-nov/toiture/toit2.jpg'

import ext1 from './image-nov/pavé-ext/ext1.jpg'
import ext2 from './image-nov/pavé-ext/ext2.jpg'

const carrelages = [
    { id: 1, src: carr1, alt: 'carr 1', },
    { id: 2, src: carr2, alt: 'carr 2', },
    { id: 3, src: carr3, alt: 'carr 3', },
];

const pavés = [
    { id: 1, src: pav1, alt: 'pav 1', },
    { id: 2, src: pav2, alt: 'pav 2', },
    { id: 3, src: pav3, alt: 'pav 3', },
    { id: 4, src: pav4, alt: 'pav 4', },
];

const rdc = [
    { id: 2, src: fini1, alt: 'fini 1', },
    { id: 1, src: fini2, alt: 'fini 2', },
    { id: 3, src: salon1, alt: 'salon 1', },
    { id: 4, src: salon2, alt: 'salon 2', },
];

const sdb = [
    { id: 1, src: sdb1, alt: 'sdb 1', },
    { id: 2, src: sdb2, alt: 'sdb 2', },
];

const toiture = [
    { id: 1, src: toit2, alt: 'toit 2', },
    { id: 2, src: toit1, alt: 'toit 1', },
];

const ext = [
    { id: 1, src: ext1, alt: 'ext 1', },
    { id: 2, src: ext2, alt: 'ext 2', },
];


const Realisations = () => {

    return (
        <Container>
            <h1 className='text-center'>Réalisations</h1>
            <br /><br />
            <Fade bottom>
            <Row>
                <Col style={{ borderRight: '1px solid #0f77e0bd' }}>
                    <Carousel style={{ width: '450px', height: '600px' }}>
                        {carrelages.map((image) => (
                            <Carousel.Item key={image.id}>
                                <img
                                    className="d-block w-100 img-fluid"
                                    src={image.src}
                                    alt={image.title}
                                    style={{ width: '450px', height: '600px', objectFit: 'cover' }}
                                />
                                <Carousel.Caption>
                                    <h3>{image.title}</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col>
                    <h2>Carrelage</h2>
                    <p>rénovation de la chape avec pose de carrelage</p>
                </Col>
            </Row>
            <br /><br />
            <Row>
                <Col style={{ borderRight: '1px solid #0f77e0bd' }}>
                    <Carousel style={{ width: '450px', height: '600px' }}>
                        {pavés.map((image) => (
                            <Carousel.Item key={image.id}>
                                <img
                                    className="d-block w-100 img-fluid"
                                    src={image.src}
                                    alt={image.title}
                                    style={{ width: '450px', height: '600px', objectFit: 'cover' }}
                                />
                                <Carousel.Caption>
                                    <h3>{image.title}</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col>
                    <h2>Pavé</h2>
                    <p>Pose de pavé en extérieure</p>
                </Col>
            </Row>
            <br /><br />
            <Row>
                <Col style={{ borderRight: '1px solid #0f77e0bd' }}>
                    <Carousel style={{ width: '450px', height: '600px' }}>
                        {rdc.map((image) => (
                            <Carousel.Item className='port' key={image.id}>
                                <img
                                    className="d-block w-100 img-fluid"
                                    src={image.src}
                                    alt={image.title}
                                    style={{ width: '450px', height: '600px', objectFit: 'cover' }}
                                />
                                <Carousel.Caption>
                                    <h3>{image.title}</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col>
                    <h2>Salle de bain et studio</h2>
                    <p>Rénovation entièrement du studio</p>
                </Col>
            </Row>
            <br /><br />
            <Row>
                <Col style={{ borderRight: '1px solid #0f77e0bd' }}>
                    <Carousel style={{ width: '450px', height: '600px' }}>
                        {sdb.map((image) => (
                            <Carousel.Item key={image.id}>
                                <img
                                    className="d-block w-100 img-fluid"
                                    src={image.src}
                                    alt={image.title}
                                    style={{ width: '450px', height: '600px', objectFit: 'cover' }}
                                />
                                <Carousel.Caption>
                                    <h3>{image.title}</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col>
                    <h2>Salle de bain</h2>
                    <p>Rénovation salle de bain</p>
                </Col>
            </Row>
            <br /><br />
            <Row>
                <Col style={{ borderRight: '1px solid #0f77e0bd' }}>
                    <Carousel style={{ width: '450px', height: '600px' }}>
                        {toiture.map((image) => (
                            <Carousel.Item key={image.id}>
                                <img
                                    className="d-block w-100 img-fluid"
                                    src={image.src}
                                    alt={image.title}
                                    style={{ width: '450px', height: '600px', objectFit: 'cover' }}
                                />
                                <Carousel.Caption>
                                    <h3>{image.title}</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col>
                    <h2>Toiture</h2>
                    <p>rénovation de la toiture du hangar</p>
                </Col>
            </Row>
            <br /><br />
            <br /><br />
            <Row>
                <Col style={{ borderRight: '1px solid #0f77e0bd' }}>
                    <Carousel style={{ width: '450px', height: '600px' }}>
                        {ext.map((image) => (
                            <Carousel.Item key={image.id}>
                                <img
                                    className="d-block w-100 img-fluid"
                                    src={image.src}
                                    alt={image.title}
                                    style={{ width: '450px', height: '600px', objectFit: 'cover' }}
                                />
                                <Carousel.Caption>
                                    <h3>{image.title}</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col>
                    <h2>Pavé trottoir</h2>
                    <p>Remplacement avec de nouveau pavés pour trottoir</p>
                </Col>
            </Row>
            </Fade>
            <br /><br />
        </Container>
    );
};

export default Realisations;
