import React from 'react';
import { Image, Container, Row, Col, Button } from 'react-bootstrap';
import Carrousel from '../Carrousel/Carrousel';
import Fade from 'react-reveal/Fade';
import './Home.css';
import image from './image.JPG'
import image1 from './image1.JPG'
import YouTube from 'react-youtube';

function Home() {
  return (
    <div className="mb-5">
      <Carrousel />
      {/*
      <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col xs={12} md={6} >
            <YouTube/>
            </Col>
  </Row>*/}
      <Container fluid>
      <div class="text-left">
        <Fade bottom>
          <Row className="my-3">
          
          
            <Col xs={12} md={6} >
              <Image src={image} alt="Responsive image" class="mx-auto d-block text-center" fluid />
            </Col>
            <Col xs={12} md={6}>
              <h1>Histoire</h1>
              <p>
                Nova Blex est une entreprise créée récemment, spécialisée dans la rénovation intérieure et extérieure des bâtiments résidentiels et commerciaux. Avec plus de 5 ans d'expérience dans le domaine de la construction, notre équipe de professionnels s'engage à fournir des services de haute qualité à ses clients.<br /><br />

                L'un des arguments clés est son approche personnalisée pour chaque projet. <br /><br />Chaque client est traité de manière individuelle, avec une attention particulière accordée à ses besoins et ses préférences. Nous travaillons en étroite collaboration avec les clients pour comprendre leurs objectifs et créer des solutions de rénovation sur mesure qui répondent à vos attentes.
              </p>
              <br/>
              <Button className='custom' variant="outline-dark" href="/About">À propos de nous</Button>
            </Col>
          </Row>
          <br /><br />
          <Row className="my-3">
            <Col xs={12} md={6}>
              <h1>Rénovation</h1>
              <p>
                Notre entreprise de rénovation possède une expertise inégalée en matière de rénovation de bâtiments. Nous sommes fiers de notre réputation de fournir un travail de qualité supérieure à nos clients, avec une attention particulière portée aux détails. <br /><br />

                Nous sommes une équipe de professionnels passionnés, dévoués à fournir des services de rénovation exceptionnels. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins et nous assurons que chaque projet est réalisé selon les normes de qualité les plus élevées. <br /><br />

                Avec de nombreuses années d'expérience dans la construction en Italie et en Belgique, nous sommes en mesure de gérer tous les aspects de la rénovation, de la planification initiale à l'exécution finale. Nous sommes fiers de notre capacité à travailler avec des matériaux de haute qualité, ainsi que de notre connaissance approfondie des tendances actuelles en matière de design et de construction.<br /><br />

                Nous sommes également engagés à fournir un service de qualité à nos clients, ce qui signifie que nous sommes disponibles pour répondre à toutes leurs questions et préoccupations tout au long du processus de rénovation. Nous sommes convaincus que cette approche axée sur le client est la clé de notre succès et nous sommes fiers de notre capacité à fournir un service exceptionnel à chaque client.<br /><br />

                En somme, si vous cherchez une entreprise de rénovation qui possède une expertise inégalée, qui offre un travail de qualité supérieure, qui a de l'expérience et qui est dévouée à fournir un service de qualité, vous êtes au bon endroit. Nous sommes impatients de travailler avec vous pour créer l'espace de vos rêves. <br /><br />
              </p>
              <Button className='custom' variant="outline-dark" href="/Realisations">Nos réalisations</Button>
            </Col>
            <Col xs={12} md={6}>
              <Image src={image1} alt="Responsive image" class="mx-auto d-block text-center" fluid />
            </Col>
          </Row>
          <br/><br/><br/>
          <Row >
            <Col className='text-center'>
            <h1>Intéressé ?</h1>
            <h2>Contactez-nous par téléphone au <b>32 485 71 55 54</b>, la réalisation de devis est <b>GRATUITE</b>.</h2>
            {/*<Button className='custom' variant="outline-dark" href="/Contact">Contactez-nous</Button>*/}
            </Col>
          </Row>
        </Fade>
      </div>
    </Container>
    </div>
  );
}

export default Home;